import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import {
  clearMediaFiles,
  deleteMediaItem,
  resumeDraftRepair,
  userChosenDeletedMedia,
  userChosenUploadedMedia,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  hasMediaAttached,
  isEditState,
  userChosenRelationDetails,
  fromMediaUpload,
  isMediaResume,
} from "../../ducks/redux/actions/userChoices";
import Button from "../../storybook/Button";
import UploadMediaRow from "./UploadMediaRow";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import classes from "./styles.module.scss";
import styles from "./spinner.module.scss";
import "../../compositions/UploadMedia/index.scss";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeMediaFilesId } from "../../ducks/redux/actions/userChoices";
import { validateMediaSize } from "./mediaConfigure";
import Compress from "browser-image-compression";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";

const allowedExtensions = process.env.REACT_APP_ALLOW_EXTENSION;
const imgSize = process.env.REACT_APP_IMG_SIZE as string;
const videoSize = process.env.REACT_APP_VIDEO_SIZE as string;
const pdfSize = process.env.REACT_APP_DOC_SIZE as string;

interface mediaFilesType {
  name: string;
  size: number;
  fileType: string;
}
interface UploadMediaProps extends RouteComponentProps<any> { }

const UploadMedia: React.FC<UploadMediaProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<mediaFilesType[]>([]);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const [wrongFileType, setWrongFileType] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  const[imageFileExtension, setImageFileExtensions] = useState("");
  const[fileType, setFileType] = useState('')
  let mediaLength = 0;

  let maxFileSize = +videoSize;
 

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );

  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );

  const resumeDraftRepairVal = useSelector(
    (state: any) => state?.userChoicesReducer?.resumeDraftRepair
  );

  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );

  const userChosenUploadedMediaValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenUploadedMedia
  );

  let assetIdentifier = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );

  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );

  const isResume = useSelector(
    (state: any) => state.userChoicesReducer?.isMediaResumed
  );

  const mediaIds = useSelector(
    (state: any) => state.userChoicesReducer?.mediaItems
  );

const priorityCodeValue = useSelector(
  (state: any) => state.userChoicesReducer?.repairPriority
);

  useEffect(() => {
    userChosenUploadedMediaValue?.map((mediaFile: any) => {
      if (mediaFile) {
        setMediaFiles(userChosenUploadedMediaValue);
      }
    });
    mediaLength = mediaIds?.length;
    (async () => {
      const imageExtensionCheck = await api.GetAppConfigToggleValue(
        "IsImageAllowed"
      );
      if(imageExtensionCheck?.value.toLowerCase() == 'true'){
        setImageFileExtensions(process.env.REACT_APP_IMAGE_EXTENSION || '')
        const allowedConfigExtensions = process.env.REACT_APP_IMAGE_EXTENSION
      ?.replace("[", "")
      .replaceAll("'", "");
    const allowFileType = allowedConfigExtensions?.replace("]", "");
        setFileType(allowFileType || '');
      }
      })();
  }, []);

  useEffect(() => {
    mediaLength = mediaIds?.length;
    if (mediaFiles?.length == 5) {
      setDisableUploadButton(true);
    }
  }, [mediaFiles]);

  // === Displaying media while user is coming from resume work order ===
  useEffect(() => {
    if (isResume) {
      dispatch(isMediaResume(false));
      resumeWorkOrderMedia();
    }
  }, []);

  const resumeWorkOrderMedia = async () => {
    try {
      setIsLoading(true);
      const { documents } = await api.getMediaData(workOrderObject?.id);
      documents?.map((data: any) => {
        dispatch(storeMediaFilesId(data.id));
        data?.metadata?.map((res: any) => {
          if (res.key === "content-type") {
            const name = data.media.fileName;
            const fileType = res.value;
            dispatch(userChosenUploadedMedia({ name, fileType }));
            setMediaFiles((currentMediaFiles: any) => [
              ...currentMediaFiles,
              { name, fileType },
            ]);
          }
        });
      });
    } catch (e) {
      setError("Media_SSR011c");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      UpdateWorkOrder();
    }, 1000);
  }, [mediaIds?.length]);

  const backButtonHandler = () => {
    dispatch(fromMediaUpload(false));
    props.history.push("/repairadditionaldetails");
  };

  const exitButtonHandler = () => {
    dispatch(clearMediaFiles([]));
    props.history.push("/customer360View");
  };

  const UpdateWorkOrder = async () => {
    workOrderObject.priority = priorityCodeValue;
    const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
    let woRequestParameters =
      _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

    woRequestParameters.documents = {
      documentIdentifiers: mediaIds,
    };
    try {
      await api.updateWorkOrderRepairMedia(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
    } catch (e) {
      props.history.push("/genericerror");
    }
  };

  const saveDraftAndExit = () => {
    // === update store for resume draft ===
    dispatch(fromMediaUpload(true));
    setCloseAfterSaving(true);
    props.history.push("/customer360View");
  };

  const onContinueHandler = () => {
    dispatch(fromMediaUpload(false));
    // === Validating media has attached ===
    if (mediaIds?.length > 0) {
      dispatch(hasMediaAttached("Yes"));
    } else {
      dispatch(hasMediaAttached("No"));
    }

    if (
      resumeDraftRepairVal ||
      (EditState === true && mediaLength !== mediaIds.length)
    ) {
      dispatch(resumeDraftRepair(false));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }

    if (
      eligibilityResponseObject?.statuses?.isInDefects === true &&
      vandalismConfigValue === true
    ) {
      props.history.push("/vandalismquestion");
    } else {
      props.history.push("/vulnerabilityquestion");
    }
  };

  function encodeImageFileAsURL(element: any) {
    setError("");

    let file = element[0];
    const reader = new FileReader();
    const imgExt = file.type.split("/");
    if (
      (imgExt[1] === "jpeg" || imgExt[1] === "png" || imgExt[1] === "jpg") &&
      imgExt[0] === "image"
    ) {
      // Use webworker for faster compression with
      const options = {
        maxSizeMB: file.size / 2,
        useWebWorker: true,
      };

      /* istanbul ignore next */
      Compress(file, options)
        .then((compressedBlob) => {
          // Convert the blob to file
          setIsLoading(true);
          reader.readAsDataURL(compressedBlob);
          reader.onloadend = () => {
            processMediaFile(element, file.name, reader.result, file.type);
          };
        })
        .catch((e) => { });
    } else {
      reader.onloadend = () => {
        processMediaFile(element, file.name, reader.result, file.type);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  /* istanbul ignore next */
  const processMediaFile = async (
    uploadMediaArray: any,
    name: any,
    image: any,
    fileType: any
  ) => {
    const size = validateMediaSize(fileType);

    const validExt = fileType.split("/");
    if (validExt[1] === "quicktime") {
      validExt[1] = "mov";
    } else if (validExt[1] === "x-matroska") {
      validExt[1] = "mkv";
    } else if (validExt[1] === "jpeg") {
      validExt[1] = "jpg";
    }

    if (uploadMediaArray[0].size > size) {
      setWrongFileType(false);
      setFileTooLarge(true);
    }

    if (!imageFileExtension?.includes(validExt[1])) {
      setFileTooLarge(false);
      setWrongFileType(true);
    } else {
      setFileTooLarge(false);
      setWrongFileType(false);

      // === Upload media files on server ===

      const mediaParam = {
        DocumentType: "WORKORDER",
        Media: image?.substr(image?.indexOf(",") + 1),
        Metadata: [
          {
            key: "customer-identifier",
            value: customerIdentifier,
          },
          {
            key: "asset-identifier",
            value: assetIdentifier,
          },
          {
            key: "works-order-identifier",
            value: workOrderObject.id,
          },
          {
            key: "filename",
            value: uploadMediaArray[0].name,
          },

          {
            key: "content-type",
            value: fileType,
          },
        ],
      };

      try {
        setIsLoading(true);
        const response = await api.postMediaFile(
          workOrderObject.id,
          mediaParam
        );

        // ===== Updating patch work work when media uploaded successfully ====
        dispatch(storeMediaFilesId(response.documentIdentifier));
        dispatch(userChosenUploadedMedia({ name, fileType }));
        setMediaFiles((currentMediaFiles: any) => [
          ...currentMediaFiles,
          { name, fileType },
        ]);
      } catch (e) {
        setError("Media_SSR011c");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleMediaFilesChange = (event: any) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      encodeImageFileAsURL(event.target.files);
    }
  };

  const deleteMediaFile = async (id: number) => {
    setFileTooLarge(false);
    setWrongFileType(false);
    setError("");

    try {
      setIsLoading(true);
      await api.deleteMediaFiles(workOrderObject.id, mediaIds[id]);
      dispatch(deleteMediaItem(mediaIds[id]));
      // === Delete media files from database ===
      const updatedMedia = mediaFiles.filter(
        (mediaFile: any, index: number) => {
          return index != id;
        }
      );
      setMediaFiles(updatedMedia);
      dispatch(userChosenDeletedMedia(updatedMedia));
      setDisableUploadButton(false);
    } catch (e) {
      setError("Media_SSR011b");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrag = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragover" || e.type === "dragenter") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  /* istanbul ignore next */
  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      if (mediaFiles.length < 5) {
        encodeImageFileAsURL(event.dataTransfer.files);
      }
    } else {
      console.log("File was not uploaded.");
    }
  };

  const onButtonClick = () => {
    inputRef.current!.click();
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="main-container">
        {isLoading && (
          <div className="upload-overlay">
            <div id="UploadingOverlay">
              <div className={styles.overlayContainer}>
                <div className={styles.overlayContent}>
                  <div className={styles.spinner}></div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="padding-repair">

          <h1
            data-testid="Upload_Media_Title"
            className="header-size"
          >
            {t("Upload_Media_Title")}
          </h1>
          <Message className="info-msg">
            {t("Upload_Media_Initial_Alert")}
            <p>{`${t("Upload_Media_Size")}`}

            </p>
          </Message>

          <p className="pb-16">
            {t("Upload_Media_SubHeading1")} ({mediaFiles.length}/5)
          </p>
        </div>
        <div className="padding-repair">
          <div className="upload-section">
            <form
              id="form-file-upload"
              className="error-section"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              data-testid="form-file-upload"
            >
              {fileTooLarge && (
                <>
                  <Message className="error-msg text-left">
                    {t("Upload_Media_Attachments_Too_Large")}
                  </Message>
                </>
              )}

              {error && (
                <>
                  <Message className="error-msg text-left">
                    {t(error)}
                  </Message>
                </>
              )}

              {wrongFileType && (
                <>
                  <Message className="warning-msg text-left">
                    {t("Upload_Media_Wrong_File_Type")}
                  </Message>
                </>
              )}

              {mediaFiles.length >= 5 && (
                <>
                  <Message className="warning-msg text-left">
                    {t("Upload_Media_Max_Attachments")}
                  </Message>
                </>
              )}
              <input
                className="input-file-upload"
                ref={inputRef}
                type="file"
                id="input-file-upload"
                onChange={handleMediaFilesChange}
                disabled={disableUploadButton}
                data-testid="input-file-upload"
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={
                  dragActive
                    ? classes["label-file-upload-drag-active"]
                    : "pt-16"
                }
              >
                <p className="para-h5"
                >
                  {t("Upload_Media_Initial_Text1")}
                </p>
                <p className="para-h5"
                >
                  {t("Upload_Media_Initial_Text2")}
                </p>
                <p
                  onClick={onButtonClick}
                  onKeyPress={onButtonClick}
                  data-testid="browse-button"
                >
                  <input
                    type="button"
                    name="mediaFiles"
                    value="Upload"
                    className={disableUploadButton ? classes.disabled : "secondary-ghost-btn"}
                    disabled={disableUploadButton}
                  />
                </p>
              </label>
              {dragActive && (
                <div
                  className={classes["drag-file-element"]}
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  data-testid="drag-file-element"
                ></div>
              )}
            </form>



            {mediaFiles.length >= 1 && (
              <div className="files-section">
                {mediaFiles.map((mediaFile: any, key: number) => {
                  return (
                    <UploadMediaRow
                      key={key}
                      mediaFileName={mediaFile.name}
                      type={mediaFile.fileType}
                      bgColour={""}
                      deleteMediaFile={() => deleteMediaFile(key)}
                      {...props}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 marginTop-auto" id="repAddDet-div5">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
              <BackModal id="custDetails-backmodal" back={backButtonHandler} />

              <ExitModalAndSave
                id="supp-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
            </div>
            <div className="col text-end padding-repair">
              <Button
                id="supp-btn1"
                onClick={onContinueHandler}
                className="primary-btn"
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default UploadMedia;
