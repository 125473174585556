import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import { RouteComponentProps, useHistory } from "react-router-dom";
import HeaderJourney from "../../storybook/Header/HeaderJourney"
import Button from "../../Button/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import BackModal from "../Modal/BackModal";
import {
  userChosenEmergencyQuestion,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  resumeDraftRepair,
} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import PageHeader from "../../storybook/PageHeader/PageHeader";
import Message from "../../storybook/Message";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
interface RepairEmergencyProps extends RouteComponentProps<any> {}
const RepairEmergency: React.FC<RepairEmergencyProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const [radiovalue, setRadioValue] = useState("");
  const [buttondisable, setsetButtonDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  const userChosenEmergencyQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenEmergencyQuestion
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) => state.dashboardReducer.formState.fetchedRepairReasonsSelect
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  // update work order
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );

  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDataVal = useSelector(
    (state: any) => state.userChoicesReducer.userResumeData
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );

 const priorityCodeValue = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );

  const UpdateWorkOrder = async () => {
    try {
      workOrderObject.priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.isEmergency = true;
      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      props.history.push("/GenericError");
    } finally {
    }
  };
  
  useEffect(() => {
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    }
    if (props.history.action === "POP") {
      setRadioValue(userChosenEmergencyQuestionBackButton);
      if (
        userChosenEmergencyQuestionBackButton ==
        t("Repair_Emergency_Radio3_Text")
      ) {
        setsetButtonDisable(true);
      } else {
        setsetButtonDisable(false);
      }
    }
    if (resumeDraftRepairVal) {
      setRadioValue(userChosenEmergencyQuestionBackButton);
      if (
        !(
          userResumeDataVal?.resumePriority === "Emergency" &&
          userResumeDataVal?.resumeSelectedProperty === "Property"
        )
      ) {
        props.history.replace("/duplicaterepair");
      }
    } else {
      if (!(priority === "Emergency" && selectedproperty === "Property")) {
        props.history.replace("/duplicaterepair");
      }
      setRadioValue(userChosenEmergencyQuestionBackButton);
    }
  }, []);

  const updateSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }

    if (value == t("Repair_Emergency_Radio3_Text")) {
      setErrorMessage(
        <div
          data-testid="alert-warning"
          role="alert"
          placeholder="alertNo"
          className="error-heading"
        >
          <Message className="error-msg">
              {t("SSR068")}
          </Message>
        </div>
      );
      setsetButtonDisable(true);
    } else {
      setErrorMessage("");
      setsetButtonDisable(false);
    }
    setRadioValue(value);    
    dispatch(userChosenEmergencyQuestion(value));
  };

  const isEmergencyValue = t("Repair_Emergency_Radio1_Text");

  const backButtonHandler = () => {
    if (
      fetchedRepairReasonsSelection[0]?.additionalData.length > 0 &&
      fetchedRepairReasonsSelection[0]?.additionalData[0]?.description != ""
    ) {
      props.history.replace("/supplementaryquestion");
    } else {
      props.history.replace("/repairlocation");
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    if (radiovalue === "") {
      props.history.replace("/customer360View");
    }
    setCloseAfterSaving(true);
    props.history.replace("/customer360View");
  };

  const callPageHandler = () => {
    if(radiovalue === t("Repair_Emergency_Radio1_Text")) {
      UpdateWorkOrder();
    }
    if (resumeDraftRepairVal) {
      const { emergencyQuestion } = userResumeDraftDataCache;
      if (emergencyQuestion !== radiovalue) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenDuplicateQuestion(""));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (radiovalue === "") {
      setErrorMessage(
        <div
          data-testid="alert-warning"
          role="alert"
          placeholder="alert"
        >
          <Message className="warning-msg">
              {t("SSR054")}
          </Message>
        </div>
      );
      return;
    }
    props.history.push("/duplicaterepair");
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      
      <div className="container-fluid parent-footer p-0" id="repemer-container">
      <div id="repairemer-main-div" className="padding-repair">

        <div className="mb-30">
          <PageHeader>
            {t("Repair_Emergency_Header_Text")}
          </PageHeader>
        </div>
        {errorMessage}
        <div id="repairemer-div1" className="repair-text mb-16 ">
          <RadioGroup
            id="repairemer-radiogrp"
            onChange={updateSelection}
            defaultValue={radiovalue}
            value={radiovalue}
          >
            <FormControlLabel
              id="repairemer-label1"
              value={t("Repair_Emergency_Radio1_Text")}
              control={<Radio id="repairemer-radio1" />}
              label={t("Repair_Emergency_Radio1_Text")}
              placeholder="yes"
            />
            <FormControlLabel
              id="repairemer-label2"
              value={t("Repair_Emergency_Radio2_Text")}
              control={<Radio id="repairemer-radio2" />}
              label={t("Repair_Emergency_Radio2_Text")}
              placeholder="next"
            />
            <FormControlLabel
              id="repairemer-label3"
              value={t("Repair_Emergency_Radio3_Text")}
              control={<Radio id="repairemer-radio3" />}
              label={t("Repair_Emergency_Radio3_Text")}
              placeholder="no"
            />
          </RadioGroup>
        </div>
        
      </div>
      <div className="col-md-12 marginTop-auto">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
              <BackModal id="repemer-backmodal" back={backButtonHandler} />
              <ExitModalAndSave
                id="repairemer-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
             </div>
             <div className="col text-end padding-repair">
              <Button
                id="repairemer-btn1"
                data-testid="continue"
                disabled={buttondisable}
                onClick={callPageHandler}
                className="primary-btn"
              >
                {t("continuen_button")}{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairEmergency;
