import { AnyAction } from "redux";
import {
  REPAIR_PRIORITY,
  USERCHOSEN_PROPERTY_ID,
  USERCHOSEN_REPORTER_ID,
  USERCHOSEN_PROPERTY_TYPE,
  USERCHOSEN_PROPERTY_ADDRESS,
  USERCHOSEN_REPAIR_LOCATION,
  USERCHOSEN_HIGH_LEVEL_DESCRIPTION,
  USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  USERCHOSEN_EMERGENCY_QUESTION,
  USERCHOSEN_DUPLICATE_QUESTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT,
  USERCHOSEN_VANDALISM_QUESTION,
  USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  USERCHOSEN_COVID_QUESTION,
  USERCHOSEN_VULNERABILITY_QUESTION,
  USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_DETAILS,
  USERCHOSEN_UPRN,
  USERCHOSEN_SOR,
  USERCHOSEN_STATUS,
  USERCHOSEN_ELIGIBILITY_ERRORCODE,
  USERCHOSEN_CONTACT_NAME,
  USERCHOSEN_CONTACT_PHONE,
  USERCHOSEN_RELATION_DETAILS,
  USERCHOSEN_REPAIR_LOCATION_ID,
  LOGIN_TIME,
  REFRESH_TOKEN,
  LOGIN_STATUS,
  ACCESS_TOKEN_EXPIRE_TIME,
  REFRESS_TOKEN_EXPIRE_TIME,
  INITIAL_LOGIN_TIME,
  UPDATE_PREV_CONFIRMED_STATE,
  UPDATE_ROUTE_HISTORY,
  UPDATE_USER_CHOICES_WITH_NEW_CHANGE,
  Is_Edit_State,
  USERCHOSEN_BANLANCE_TYPE,
  USERCHOSEN_ACCOUNT_NUMBER,
  USERCHOSEN_BALANCE,
  USERCHOSEN_ACCOUNT_Type,
  USERCHOSEN_Other_BALANCE,
  USER_PROFILE_ETAG,
  USER_PROFILE_DATA,
  Allpay_Conformation_Message,
  USERCHOSEN_ERROR_CODE,
  USERCHOSEN_ISELIGIBLE,
  RESUME_DRAFT_REPAIR,
  USER_REPAIR_WORKORDER,
  USER_RESUME_DATA,
  USER_RESUME_DRAFT_DATA_CACHE,
  USERCHOSEN_ISREPORTER,
  USERCHOSEN_ISERROR,
  VIEW_STATEMENT_DATA,
  USERCHOSEN_ACCOUNT_Identifier,
  USERCHOSEN_UPLOADED_MEDIA,
  MY_MESSAGE_DATA,
  ASSET_IDENTIFIERS,
  IS_CATALYST_REPAIR_DISABLED_AREA,
  CLEAR_ALL_STATE,
  USERCHOSEN_RESUME_REPAIR,
  LOAD_DUPLICATE_REPAIR_PAGE,
  USER_CREATED_NEW_REPAIR,
  UPLOADED_MEDIA_ITEMS,
  DELETE_MEDIA_ITEM,
  CLEAR_MEDIA_FILES,
  USERCHOSEN_DELETE_MEDIA,
  HAS_MEDIA_ATTACHED,
  FROM_MEDIA_PAGE,
  SELECTED_HISTORY_MEDIA,
  MEDIA_RESUME,
  ENERGY_ADVICE_MAX_COUNT,
  CASES_USERCHOSEN_PROPERTY_ID,
  ASSET_REGION,
  IS_CATALYST_CUSTOMER,
  IS_WORKORDER_TOGGLE,
  IS_EDIT_PROFILE_TOGGLE,
  TAB_SELECTED_VALUE,
  SHOW_MENU_TAB,
  SHOW_FOOTER,
  NAV_HEADER_TEXT,
  BLOCK_IDENTIFIER,
  USER_SELECTED_MESSAGE_DETAILS,
  USER_SELECTED_MESSAGE_SUBJECT,
  USER_SELECTED_MESSAGE_DATE,
  USER_SELECTED_MESSAGE_SENDER,
  USER_SELECTED_MESSAGE_NOTIFICATION_TYPE,
  USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER,
  SINGLE_TENANCY,
  NAVIGATED_FROM,
  USER_SELECTED_MESSAGE_STATUS,
  IS_CASE_CATALYST_TOGGLE,
  BOROUGH_NAME,
  USER_SELECTED_TENANCY_GROUP,
  IS_ON_HOLD_REPAIR,
  DEMOBILIZED_CONTRACTOR_SITES,
  IS_MYPB_REPAIRS_MAINTENANCE_VALUE
} from "../actionTypes";
import { demobilizedContractorSites, isMyPbRepairsMaintainence } from "../actions/userChoices";

const initialState = {
  userChosenPropertyId: "",
  userChosenPropertyType: "",
  userChosenPropertyAddress: "",
  userChosenRepairLocationId: 0,
  userChosenRepairLocation: "",
  userChosenHighLevelDescription: "",
  userChosenSupplementaryQuestions: "",
  userChosenEmergencyQuestion: "",
  userChosenDuplicateQuestion: "",
  userChosenAdditionalDescription: "",
  userChosenVandalismQuestion: "No",
  userChosenVandalismAdditionalInformation: "",
  userChosenCovidQuestion: "",
  userChosenVulnerabilityQuestion: "",
  userChosenVulnerabilityAdditionalInformation: "",
  userChosenContactDetails: "",
  userChosenUPRN: "",
  userChosenSOR: "",
  userChosenSTATUS: "",
  userChosenEligibilityErrorCode: "",
  loginTime: "",
  refershToken: "",
  loginStatus: "",
  refreshTokenExpirationTime: "",
  accessTokenExpirationTime: "",
  initialLoginTime: "",
  previousConfirmedState: {},
  routeHistory: [],
  banlancetype: "",
  accountnumber: "",
  balance: "",
  accountType: "",
  OtherBalance: "",
  userProfileETag: "",
  userProfileData: {},
  conformationMessage: "",
  errorCode: "",
  isEligible: false,
  resumeDraftRepair: false,
  userRepairWorkOrder: {},
  userResumeData: {},
  userResumeDraftDataCache: {},
  userChosenIsReporter: false,
  userIsError: false,
  userChosenResumeRepair: false,
  loadDuplicateRepairPage: false,
  userCreatedNewRepair: false,
  viewStatementData: {},
  accountidentifier: "",
  userChosenUploadedMedia: [],
  myMessageData: {},
  allassetIdentifiers: "",
  isCatalystCustomer: false,
  isCaseCatalystToggle: false,
  isWorkOrderToggle: "false",
  isEditProfileToggle: "false",
  isMediaResumed: false,
  mediaItems: [],
  mediaAttached: "No",
  isFromMediaPage: false,
  isSelectedHistoryMedia: "",
  navHeaderText: "",
  messageDetails: "",
  messageSubject: "",
  messageDate: "",
  messageSender: "",
  messageNotificationType: "",
  messageDocumentIdentifier: "",
  messageStatus: "",
  showFooter: false,
  userNavigatedFrom: "",
  borough:"",
  selectedTenancyGroup : "",
  isOnHoldRepair: false,
  repairPriority: ""
};

export const userChoices = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {

    case CLEAR_ALL_STATE:
      return {
        state: initialState
      }
    case IS_EDIT_PROFILE_TOGGLE:
      return {
        ...state,
        isEditProfileToggle: action.payload,
      };

    case IS_WORKORDER_TOGGLE:
      return {
        ...state,
        isWorkOrderToggle: action.payload,
      };
    case IS_CATALYST_CUSTOMER:
      return {
        ...state,
        isCatalystCustomer: action.payload,
      };
    case IS_CASE_CATALYST_TOGGLE:
      return {
        ...state,
        isCaseCatalystToggle: action.payload,
      };
    case IS_CATALYST_REPAIR_DISABLED_AREA:
      return {
        ...state,
        isCatalystDisabledArea: action.payload,
      };
    case USERCHOSEN_PROPERTY_ID:
      return {
        ...state,
        userChosenPropertyId: action.payload,
      };

    case USERCHOSEN_PROPERTY_TYPE:
      return {
        ...state,
        userChosenPropertyType: action.payload,
      };

    case USERCHOSEN_PROPERTY_ADDRESS:
      return {
        ...state,
        userChosenPropertyAddress: action.payload,
      };

    case USERCHOSEN_REPAIR_LOCATION:
      return {
        ...state,
        userChosenRepairLocation: action.payload,
      };

    case USERCHOSEN_HIGH_LEVEL_DESCRIPTION:
      return {
        ...state,
        userChosenHighLevelDescription: action.payload,
      };

    case USERCHOSEN_SUPPLEMENTARY_QUESTIONS:
      return {
        ...state,
        userChosenSupplementaryQuestions: action.payload,
      };

    case USERCHOSEN_EMERGENCY_QUESTION:
      return {
        ...state,
        userChosenEmergencyQuestion: action.payload,
      };

    case USERCHOSEN_DUPLICATE_QUESTION:
      return {
        ...state,
        userChosenDuplicateQuestion: action.payload,
      };

    case USERCHOSEN_ADDITIONAL_DESCRIPTION:
      return {
        ...state,
        userChosenAdditionalDescription: action.payload,
      };

    case USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT:
      return {
        ...state,
        userChosenAdditionalDescriptionCOUNT: action.payload,
      };

    case USERCHOSEN_VANDALISM_QUESTION:
      return {
        ...state,
        userChosenVandalismQuestion: action.payload,
      };
    case USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION:
      return {
        ...state,
        userChosenVandalismAdditionalInformation: action.payload,
      };
    case USERCHOSEN_COVID_QUESTION:
      return {
        ...state,
        userChosenCovidQuestion: action.payload,
      };

    case USERCHOSEN_VULNERABILITY_QUESTION:
      return {
        ...state,
        userChosenVulnerabilityQuestion: action.payload,
      };

    case USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION:
      return {
        ...state,
        userChosenVulnerabilityAdditionalInformation: action.payload,
      };

    case USERCHOSEN_CONTACT_DETAILS:
      return {
        ...state,
        userChosenContactDetails: action.payload,
      };

    case USERCHOSEN_CONTACT_NAME:
      return {
        ...state,
        userChosenContactName: action.payload,
      };

    case USERCHOSEN_CONTACT_PHONE:
      return {
        ...state,
        userChosenContactPhone: action.payload,
      };

    case USERCHOSEN_RELATION_DETAILS:
      return {
        ...state,
        userChosenRelationDetails: action.payload,
      };
    case USERCHOSEN_ISREPORTER:
      return {
        ...state,
        userChosenIsReporter: action.payload,
      };

    case USERCHOSEN_REPORTER_ID:
      return {
        ...state,
        userChosenReporterId: action.payload,
      };

    case USERCHOSEN_UPRN:
      return {
        ...state,
        userChosenUPRN: action.payload,
      };

    case USERCHOSEN_SOR:
      return {
        ...state,
        userChosenSOR: action.payload,
      };

    case USERCHOSEN_STATUS:
      return {
        ...state,
        userChosenSTATUS: action.payload,
      };

    case USERCHOSEN_ELIGIBILITY_ERRORCODE:
      return {
        ...state,
        userChosenEligibilityErrorCode: action.payload,
      };

    case USERCHOSEN_REPAIR_LOCATION_ID:
      return {
        ...state,
        userChosenRepairLocationId: action.payload,
      };
    case LOGIN_TIME:
      return {
        ...state,
        loginTime: action.payload,
      };
    case INITIAL_LOGIN_TIME:
      return {
        ...state,
        initialLoginTime: action.payload,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        refershToken: action.payload,
      };
    case LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };
    case ACCESS_TOKEN_EXPIRE_TIME:
      return {
        ...state,
        accessTokenExpirationTime: action.payload,
      };
    case REFRESS_TOKEN_EXPIRE_TIME:
      return {
        ...state,
        refreshTokenExpirationTime: action.payload,
      };
    case UPDATE_PREV_CONFIRMED_STATE:
      return {
        ...state,
        previousConfirmedState: action.payload,
      };
    case Is_Edit_State:
      return {
        ...state,
        isEditState: action.payload,
      };
    case USERCHOSEN_BANLANCE_TYPE:
      return {
        ...state,
        banlancetype: action.payload,
      };
    case USERCHOSEN_ACCOUNT_NUMBER:
      return {
        ...state,
        accountnumber: action.payload,
      };
    case USERCHOSEN_ACCOUNT_Identifier:
      return {
        ...state,
        accountidentifier: action.payload,
      };
    case USERCHOSEN_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
    case USERCHOSEN_Other_BALANCE:
      return {
        ...state,
        OtherBalance: action.payload,
      };
    case USER_PROFILE_ETAG:
      return {
        ...state,
        userProfileETag: action.payload,
      };
    case USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case USERCHOSEN_ACCOUNT_Type:
      return {
        ...state,
        accountType: action.payload,
      };

    case UPDATE_ROUTE_HISTORY:
      return {
        ...state,
        routeHistory: [...state.routeHistory, action.payload],
      };

    case Allpay_Conformation_Message:
      return {
        ...state,
        conformationMessage: action.payload,
      };
    case USERCHOSEN_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };
    case USERCHOSEN_ISELIGIBLE:
      return {
        ...state,
        isEligible: action.payload,
      };
    case RESUME_DRAFT_REPAIR:
      return {
        ...state,
        resumeDraftRepair: action.payload,
      };
    case USER_REPAIR_WORKORDER:
      return {
        ...state,
        userRepairWorkOrder: action.payload,
      };
    case USER_RESUME_DATA:
      return {
        ...state,
        userResumeData: action.payload,
      };
    case USER_RESUME_DRAFT_DATA_CACHE:
      return {
        ...state,
        userResumeDraftDataCache: action.payload,
      };
    case USERCHOSEN_ISERROR:
      return {
        ...state,
        userIsError: action.payload,
      };

    case USERCHOSEN_RESUME_REPAIR:
      return {
        ...state,
        userChosenResumeRepair: action.payload,
      };
    case LOAD_DUPLICATE_REPAIR_PAGE:
      return {
        ...state,
        loadDuplicateRepairPage: action.payload,
      };
    case USER_CREATED_NEW_REPAIR:
      return {
        ...state,
        userCreatedNewRepair: action.payload,
      };
    case MEDIA_RESUME:
      return {
        ...state,
        isMediaResumed: action.payload,
      };
    case SELECTED_HISTORY_MEDIA:
      return {
        ...state,
        isSelectedHistoryMedia: action.payload,
      };
    case FROM_MEDIA_PAGE:
      return {
        ...state,
        isFromMediaPage: action.payload,
      };
    case HAS_MEDIA_ATTACHED:
      return {
        ...state,
        mediaAttached: action.payload,
      };
    case CLEAR_MEDIA_FILES:
      return {
        ...state,
        mediaItems: [],
        userChosenUploadedMedia: [],
      };
    case DELETE_MEDIA_ITEM:
      return {
        ...state,
        mediaItems: [
          ...state.mediaItems.filter((item: any) => item !== action.payload),
        ],
      };
    case UPLOADED_MEDIA_ITEMS:
      return {
        ...state,
        mediaItems: [...state.mediaItems, action.payload],
      };
    case CASES_USERCHOSEN_PROPERTY_ID:
      return {
        ...state,
        casesUserChosenPropertyId: action.payload,
      };
    case ASSET_REGION:
      return {
        ...state,
        assetRegion: action.payload,
      };
    case USERCHOSEN_DELETE_MEDIA:
      return {
        ...state,
        userChosenUploadedMedia: action.payload,
      };
    case ENERGY_ADVICE_MAX_COUNT:
      return {
        ...state,
        energyAdviceMaxCount: action.payload,
      };
    

    case UPDATE_USER_CHOICES_WITH_NEW_CHANGE:
      const presentUrlIndex = state.routeHistory.findIndex((e: any) => {
        return e === action.payload;
      });

      let newChanges: any = {};

      for (let i = presentUrlIndex + 1; i < state.routeHistory.length; i++) {
        Object.values(
          state.previousConfirmedState[
          state.routeHistory[i] != "repairadditionaldetails"
            ? state.routeHistory[i]
            : "duplicaterepair"
          ]
        ).map((ref: any) => {
          if (ref.key) {
            if (ref.key === "userChosenVandalismQuestion") {
              newChanges[ref.key] = "No";
            } else {
              newChanges[ref.key] = "";
            }
          }
          return;
        });
      }

      return {
        ...state,
        ...newChanges,
      };



    case VIEW_STATEMENT_DATA:
      return {
        ...state,
        viewStatementData: action.payload,
      };

    case USERCHOSEN_UPLOADED_MEDIA:
      return {
        ...state,
        userChosenUploadedMedia: [
          ...state.userChosenUploadedMedia,
          action.payload,
        ],
        // userChosenUploadedMedia: action.payload,
      };

    case MY_MESSAGE_DATA:
      return {
        ...state,
        myMessageData: action.payload,
      };

    case ASSET_IDENTIFIERS:
      return {
        ...state,
        allassetIdentifiers: action.payload
      };

    case TAB_SELECTED_VALUE:
      return {
        ...state,
        selectedTabValue: action.payload,
      };
    case SHOW_MENU_TAB:
      return {
        ...state,
        showMenuTabs: action.payload,
      };
    case SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.payload,
      };
    case SINGLE_TENANCY:
      return {
        ...state,
        singleTenancy: action.payload,
      };
    case NAV_HEADER_TEXT:
      return {
        ...state,
        navHeaderText: action.payload,
      };
    case BLOCK_IDENTIFIER:
      return {
        ...state,
        blockIdentifier: action.payload
      }

    case USER_SELECTED_MESSAGE_DETAILS:
      return {
        ...state,
        messageDetails: action.payload,
      };
    case USER_SELECTED_MESSAGE_SUBJECT:
      return {
        ...state,
        messageSubject: action.payload,
      };
    case USER_SELECTED_MESSAGE_DATE:
      return {
        ...state,
        messageDate: action.payload,
      }
    case USER_SELECTED_MESSAGE_SENDER:
      return {
        ...state,
        messageSender: action.payload
      }
    case USER_SELECTED_MESSAGE_NOTIFICATION_TYPE:
      return {
        ...state,
        messageNotificationType: action.payload
      }
    case USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER:
      return {
        ...state,
        messageDocumentIdentifier: action.payload
      }
    case USER_SELECTED_MESSAGE_STATUS:
      return {
        ...state,
        messageStatus: action.payload

      }
    case NAVIGATED_FROM: {
      return {
        ...state,
        userNavigatedFrom: action.payload
      }
      }
      case BOROUGH_NAME: {
        return {
          ...state,
          borough:action.payload
        }
      }

      case USER_SELECTED_TENANCY_GROUP:
        return{
          ...state,
          selectedTenancyGroup:action.payload

      }
    case IS_ON_HOLD_REPAIR: {
      return {
        ...state,
        isOnHoldRepair: action.payload
      }
    }
    case DEMOBILIZED_CONTRACTOR_SITES:
      return {
        ...state,
        demobilizedContractorSites: action.payload,
      };

    case IS_MYPB_REPAIRS_MAINTENANCE_VALUE:
      return {
        ...state,
        isMyPbRepairsMaintainence: action.payload,
      };
      case REPAIR_PRIORITY:
        return {
          ...state,
          repairPriority: action.payload,
        };

    default:
      return state;
  }
};

export default userChoices;
