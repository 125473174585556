export class RepairPriorityPayload {
    public contractorIdentifier?: string = ''
    public isEmergency?: boolean = false
    public isRecall?: boolean = false
    public repairType?: string = ''
    public isEMA?: string = ''
    public description?: string = ''
    public isVulnerable?: boolean = false
    public raisedDate?: string = ''
    public channel?: string = ''
}
export class RepairLocationPriorityPayload {
    public isEmergency?: boolean = false
    public isRecall?: boolean = false
    public repairType?: string = ''
    public isEMA?: string = ""
    public description?: string = ''
    public isVulnerable?: boolean = false
    public raisedDate?: string = ''
    public channel?: string = ''
}
export class RepairPriorityResposne {
    public isWorkingHours: boolean = false
    public isHoliday: boolean = false
    public repairPriority: string = ''
}
