import { Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";

import {
  userChosenAdditionalDescription,
  userChosenAdditionalDescriptionCOUNT,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  isEditState,
  resumeDraftRepair,
  hasMediaAttached,
  clearMediaFiles,
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../storybook/Button";
import Loader from "react-loader-spinner";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import "./index.scss";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import {
  appConfigVandalismToggle
} from "../../ducks/redux/actions/getAddress";
import { FeatureFlags } from "../contexts/FeatureFlags";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";

interface RepairAdditionalDetailsProps extends RouteComponentProps<any> { }

const RepairAdditionalDetails: React.FC<RepairAdditionalDetailsProps> = (
  props
) => {
  const { t } = useTranslation();
  const { featureMedia }: any = useContext(FeatureFlags);

  const maxLengthLimit = "188";
  const minLengthLimit = "0";
  const format = /[$^*_\{};<>]+/;
  const dispatch = useDispatch();
  const history = useHistory();
  const ADDITIONAL_INFO_TEXT_LIMIT = 188;
  const [characterCount, setCharacterCount] = useState(maxLengthLimit);
  const [count, setCount] = useState("0");
  const [value, setValue] = useState("");
  const [isError, setError] = useState("none");
  const [isLoading, setIsLoading] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const userChosenAdditionalDescriptionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenAdditionalDescription
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const userChosenAdditionalDescriptionBackButtonCOUNT = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenAdditionalDescriptionCOUNT
  );
  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) => state.dashboardReducer.formState.fetchedRepairReasonsSelect
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );

  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);

  useEffect(() => {
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    }
    if (props.history.action === "POP") {
      setValue(userChosenAdditionalDescriptionBackButton);
      setCount(userChosenAdditionalDescriptionBackButtonCOUNT);
    }
    if (userChosenAdditionalDescriptionBackButton) {
      setValue(userChosenAdditionalDescriptionBackButton);
      setCount(userChosenAdditionalDescriptionBackButtonCOUNT);
    }
  }, []);

  useEffect(() => {
    if (value !== "") {
      setError("none");
    }
  }, [value]);

  const onChange = (e: any) => {
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(hasMediaAttached("No"));
      dispatch(clearMediaFiles([]));
    }

    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }

    if (e.target.value.length < maxLengthLimit + 1) {
      e.target.value.length > ADDITIONAL_INFO_TEXT_LIMIT
        ? setCount(maxLengthLimit)
        : setCount(e.target.value.length);
    } else setCount(maxLengthLimit);

    setCharacterCount(maxLengthLimit);
    setValue(e.target.value.substr(minLengthLimit, maxLengthLimit));

    dispatch(
      userChosenAdditionalDescription(
        e.target.value.substr(minLengthLimit, maxLengthLimit)
      )
    );
    dispatch(userChosenAdditionalDescriptionCOUNT(e.target.value.length));
  };

  const helpTextValue = count + "/" + characterCount;

  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  let emergencyState = false;
  if (selectedproperty === "Property" && priority === "Emergency") {
    if (userChosenEmergencyQuestion === "Yes, I can provide access") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  } else {
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  }

  const UpdateWorkOrder = async () => {
    try {
      setIsLoading(true);
      workOrderObject.priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.additionalInformation = value;
      woRequestParameters.isEmergency = emergencyState;
      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      workOrderObject.additionalInformation = value;
      workOrderObject.isEmergency = emergencyState;
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);

    } finally {
      setIsLoading(false);
    }
  };

  const backButtonHandler = () => {
    dispatch(userChosenAdditionalDescription(""));
    if (userChosenDuplicateQuestion != "") {
      props.history.push("/duplicaterepair");
    } else {
      if (
        fetchedRepairReasonsSelection[0]?.additionalData.length > 0 &&
        fetchedRepairReasonsSelection[0]?.additionalData[0]?.description != ""
      ) {
        props.history.replace("/supplementaryquestion");
      } else {
        props.history.replace("/repairlocation");
      }
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    //Implement Save Draft and Exit functionalities here...
    let countValue = count.toString();
    if (countValue !== "0") {
      setError("none");
      UpdateWorkOrder();
    } else {
      setError("block");
    }
    setCloseAfterSaving(true);
    dispatch(workOrderRepairObject(null));
    props.history.push("/customer360View");
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { additionalInformation } = userResumeDraftDataCache;
      if (additionalInformation !== value) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }
    let countValue = count?.toString();

    if (value.trim().length <= 0) {
      setError("block");
      return;
    }
    if (countValue !== "0") {
      UpdateWorkOrder();
      setError("none");

      if (featureMedia === "true") {
        setTimeout(() => {
          props.history.push("/uploadmedia");
        }, 1000);
      } else {
        if (eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true) {

          setTimeout(() => {
            props.history.push("/vandalismquestion");
          }, 1000);

        }
        else {
          setTimeout(() => {
            props.history.push("/vulnerabilityquestion");
          }, 1000);
        }
      }
    } else {
      setError("block");
    }
  };

  return (
    <>
    
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="repAddDet-container">
        
        <div
          id="repAddDet-main-div"
          className="padding-repair"
        >
          <div id="repAddDet-div1">
            <h1 className="header-size pb-30" id="repAddDet-h1">
              {t("Repair_Additional_Details_Label1")}
            </h1>
            <div style={{ display: isError }} className="additional-error" id="repAddDet-div4" role="alert">
            <Message className="error-msg">
              {t("SSR008a")}
            </Message>
          </div>
            <div role="alert" className="additional-infomsg">
            <Message className="info-msg">
              {t("Additional_Details_Warning")}
            </Message>
          </div>
          
          <div className="row mb-4 m-0" id="repAddDet-div2">
            <div id="repAddDet-div3" data-testid="repairdetails" className="additional-order1 col-md-12 col-sm-12 col-lg-6 pt-18 ps-0 pe-0">
            <TextField
                  className="textarea"
                  helperText={helpTextValue}
                  data-testid="Repair_Additional_Details_Placeholder"
                  onChange={(e) => onChange(e)}
                  id="outlined-textarea"
                  value={value}
                  placeholder={t("Repair_Additional_Details_Placeholder")}
                  error={count >= maxLengthLimit}
                  multiline
                  label=""
                  inputProps={{"data-hj-allow": ""}} />
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6 additional-order2 pt-18 pe-0">
            <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <g clip-path="url(#clip0_960_6129)">
                      <path d="M16.5 10.5H19.5V13.5H16.5V10.5ZM16.5 16.5H19.5V25.5H16.5V16.5ZM18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30Z" fill="#1B9BAA" />
                    </g>
                    <defs>
                      <clipPath id="clip0_960_6129">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className="style-1 ml-16">{t("Helpful_information_include")}</span>
                <ul id="repAddDet-ul">
                  <li id="repAddDet-li1">
                    {t("Repair_Additional_Details_LabelOne")}
                  </li>
                  <li id="repAddDet-li2">
                    {t("Repair_Additional_Details_LabelTwo")}
                  </li>
                  <li id="repAddDet-li3">
                    {t("Repair_Additional_Details_LabelThree")}
                  </li>
                </ul>
            </div>
            </div>
          </div>          
        </div>
        <div className="col-md-12 marginTop-auto" id="repAddDet-div5">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
 
                <BackModal id="repAddDet-backmodal" back={backButtonHandler} />
                <ExitModalAndSave
                  id="repAddDet-exitmodal"
                  exit={exitButtonHandler}
                  saveDraftAndExit={saveDraftAndExit}
                  closeAfterSaving={closeAfterSaving}
                />
     </div>
                <div className="col text-end pt-24 padding-repair">
                <Button
                  id="btn_additionaldetails"
                  onClick={callPageHandler}
                  className="primary-btn"
                >
                  {t("continuen_button")}{" "}
                </Button>
              </div>
           
          </div>
        </div>
        <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>
        </div>
    </>
  );
};
export default RepairAdditionalDetails;