export const QUERY_POSTCODE = "QUERY_POSTCODE";
export const REPAIR_PRIORITY = "REPAIR_PRIORITY";
export const SAVE_TENANCIES = "SAVE_TENANCIES";
export const SAVE_ASSOCIATED_ASSETS = "SAVE_ASSOCIATED_ASSETS";
export const SAVE_CUSTOMER_TENANCIES = "SAVE_CUSTOMER_TENANCIES";
export const SAVE_AVAILABILITY_RESPONSE = "SAVE_AVAILABILITY_RESPONSE";

// action to save data for RepairProperty radio buttons
export const UPDATE_FORM = "UPDATE_FORM";
export const CHANGE_REPAIR_PROPERTY_TRACK = "CHANGE_REPAIR_PROPERTY_TRACK";
export const SAVE_SELECTED_TENANCICY = "SAVE_SELECTED_TENANCICY";
export const SAVE_CUSTOMER_ID = "SAVE_CUSTOMER_ID";
export const APP_CONFIG_VALUE = "APP_CONFIG_VALUE";
export const APP_CONFIG_COVID_VALUE = "APP_CONFIG_COVID_VALUE";
export const APP_CONFIG_VANDALISM_VALUE = "APP_CONFIG_VANDALISM_VALUE";
export const APP_CONFIG_CASE_CARD_VALUE = "APP_CONFIG_CASE_CARD_VALUE";
export const SAVE_ASSET_ADDRESS = "SAVE_ASSET_ADDRESS";
export const SAVE_IS_ELEGIBILITY_REPAIR = "SAVE_IS_ELEGIBILITY_REPAIR";
export const SAVE_DEFAULT_ADDRESS = "SAVE_DEFAULT_ADDRESS";

export const FETCH_REPAIR_REASONS = "FETCH_REPAIR_REASONS";
export const USER_TYPED_REPAIR_REASONS = "USER_TYPED_REPAIR_REASONS";
// export const USER_CHOSEN_REPAIR_REASON = "USER_CHOSEN_REPAIR_REASON";
export const IS_MYPB_REPAIRS_MAINTENANCE_VALUE = "IS_MYPB_REPAIRS_MAINTENANCE_VALUE";

export const FETCH_REPAIR_REASONS_SELECT = "FETCH_REPAIR_REASONS_SELECT";
export const SELECTED_REPAIR_REASON_OBJECT = "SELECTED_REPAIR_REASON_OBJECT";
export const WORK_ORDER_REPAIR = "WORK_ORDER_REPAIR";
export const ELIGIBILITY_RESPONSE = "ELIGIBILITY_RESPONSE";
export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
export const SAVE_CUSTOMERS_PHONE_NUMBERS = "SAVE_CUSTOMERS_PHONE_NUMBERS";
export const SAVE_CUSTOMERID = "SAVE_CUSTOMERID";
export const SAVE_CONTRACTOR_NAME = "SAVE_CONTRACTOR_NAME";
export const SAVE_REFERENCE_NUMBER = "SAVE_REFERENCE_NUMBER";
export const SAVE_PREFERENCE_NUMBER = "SAVE_PREFERENCE_NUMBER";
export const SAVE_MAINTRESP = "SAVE_MAINTRESP";
export const SAVE_REPRESP = "SAVE_REPRESP";
export const SAVE_HEATINGCASE = "SAVE_HEATINGCASE";

export const USERCHOSEN_PROPERTY_ID = "USERCHOSEN_PROPERTY_ID";
export const USERCHOSEN_PROPERTY_TYPE = "USERCHOSEN_PROPERTY_TYPE";
export const USERCHOSEN_PROPERTY_ADDRESS = "USERCHOSEN_PROPERTY_ADDRESS";
export const USERCHOSEN_REPAIR_LOCATION = "USERCHOSEN_REPAIR_LOCATION";
export const USERCHOSEN_HIGH_LEVEL_DESCRIPTION =
  "USERCHOSEN_HIGH_LEVEL_DESCRIPTION";
export const USERCHOSEN_SUPPLEMENTARY_QUESTIONS =
  "USERCHOSEN_SUPPLEMENTARY_QUESTIONS";
export const USERCHOSEN_EMERGENCY_QUESTION = "USERCHOSEN_EMERGENCY_QUESTION";
export const USERCHOSEN_DUPLICATE_QUESTION = "USERCHOSEN_DUPLICATE_QUESTION";
export const USERCHOSEN_ADDITIONAL_DESCRIPTION =
  "USERCHOSEN_ADDITIONAL_DESCRIPTION";
export const USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT =
  "USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT";
export const USERCHOSEN_VANDALISM_QUESTION = "USERCHOSEN_VANDALISM_QUESTION";
export const USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION =
  "USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION";
export const USERCHOSEN_COVID_QUESTION = "USERCHOSEN_COVID_QUESTION";
export const USERCHOSEN_VULNERABILITY_QUESTION =
  "USERCHOSEN_VULNERABILITY_QUESTION";
export const USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION =
  "USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION";
export const USERCHOSEN_CONTACT_DETAILS = "USERCHOSEN_CONTACT_DETAILS";
export const USERCHOSEN_RELATION_DETAILS = "USERCHOSEN_RELATION_DETAILS";
export const USERCHOSEN_CONTACT_NAME = "USERCHOSEN_CONTACT_NAME";
export const USERCHOSEN_CONTACT_PHONE = "USERCHOSEN_CONTACT_PHONE";
export const USERCHOSEN_UPRN = "USERCHOSEN_UPRN";
export const USERCHOSEN_SOR = "USERCHOSEN_SOR";
export const USERCHOSEN_STATUS = "USERCHOSEN_STATUS";
export const USERCHOSEN_ELIGIBILITY_ERRORCODE =
  "USERCHOSEN_ELIGIBILITY_ERRORCODE";
export const REPAIRS_PROPERTY_LOAD = "REPAIRS_PROPERTY_LOAD";
export const REPAIRS_SEARCH = "REPAIRS_SEARCH";
export const REPAIRS_SEARCH_SUCCESS = "REPAIRS_SEARCH_SUCCESS";
export const PAGE_CHANGED = "PAGE_CHANGED";
export const RESULTS_PER_PAGE_CHANGED = "RESULTS_PER_PAGE_CHANGED";
export const REPAIR_STATUS_CHANGED = "REPAIR_STATUS_CHANGED";
export const GET_REPAIR_STATUS_OPTIONS_SUCCESS =
  "GET_REPAIR_STATUS_OPTIONS_SUCCESS";
export const DEFAULT_REPAIR_STATUS = "DEFAULT_REPAIR_STATUS";
export const GET_SORTABLE_FIELDS_SUCCESS = "GET_SORTABLE_FIELDS_SUCCESS";
export const SORT_ORDER_CHANGED = "SORT_ORDER_CHANGED";
export const GET_REPAIR_ID = "GET_REPAIR_ID";
export const REPAIR_SEARCH_RESET = "REPAIR_SEARCH_RESET";
export const SAVE_DETERMINED_CONTRCACTOR_DETAILS =
  "SAVE_DETERMINED_CONTRCACTOR_DETAILS";

export const SAVE_CONTRCACTOR_PAYLOAD = "SAVE_CONTRCACTOR_PAYLOAD";
export const USERCHOSEN_REPAIR_LOCATION_ID = "USERCHOSEN_REPAIR_LOCATION_ID";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGIN_TIME = "LOGIN_TIME";
export const INITIAL_LOGIN_TIME = "INITIAL_LOGIN_TIME";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const REFRESS_TOKEN_EXPIRE_TIME = "REFRESS_TOKEN_EXPIRE_TIME";
export const ACCESS_TOKEN_EXPIRE_TIME = "ACCESS_TOKEN_EXPIRE_TIME";
export const SESSION_TIME_OUT_MESSAGE = "SESSION_TIME_OUT_MESSAGE";
export const UPDATE_PREV_CONFIRMED_STATE = "UPDATE_PREV_CONFIRMED_STATE";
export const UPDATE_ROUTE_HISTORY = "UPDATE_ROUTE_HISTORY";
export const UPDATE_USER_CHOICES_WITH_NEW_CHANGE =
  "UPDATE_USER_CHOICES_WITH_NEW_CHANGE";

export const REPAIR_HISTORY_DATA = "REPAIR_HISTORY_DATA";
export const VANDALISM_RESPONSE = "VANDALISM_RESPONSE";
export const REPAIR_HISTORY_CURRENT_PAGE = "REPAIR_HISTORY_CURRENT_PAGE";
export const REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE = "REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE";
export const REPAIR_DEFAULT_ASSOCIATED_ASSET = "REPAIR_DEFAULT_ASSOCIATED_ASSET";

export const USERCHOSEN_RESUME_REPAIR = "USERCHOSEN_RESUME_REPAIR";
export const LOAD_DUPLICATE_REPAIR_PAGE = "LOAD_DUPLICATE_REPAIR_PAGE";
export const USER_CREATED_NEW_REPAIR = "USER_CREATED_NEW_REPAIR";

export const Is_Edit_State = "Is_Edit_State";
export const USERCHOSEN_BANLANCE_TYPE = "USERCHOSEN_BANLANCE_TYPE";
export const USERCHOSEN_ACCOUNT_NUMBER = "USERCHOSEN_ACCOUNT_NUMBER";
export const USERCHOSEN_BALANCE = "USERCHOSEN_BALANCE";
export const USERCHOSEN_ACCOUNT_Type = "USERCHOSEN_ACCOUNT_Type";
export const USERCHOSEN_Other_BALANCE = "USERCHOSEN_Other_BALANCE";
export const USER_PROFILE_ETAG = "USER_PROFILE_ETAG";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const GET_CONTACTOR_DATA = "GET_CONTACTOR_DATA";
export const Allpay_Conformation_Message = "Allpay_Conformation_Message";
export const USERCHOSEN_ERROR_CODE = "USERCHOSEN_ERROR_CODE";
export const USERCHOSEN_ISELIGIBLE = "USERCHOSEN_ISELIGIBLE";
export const RESUME_DRAFT_REPAIR = "RESUME_DRAFT_REPAIR";
export const USER_REPAIR_WORKORDER = "USER_REPAIR_WORKORDER";
export const USER_RESUME_DATA = "USER_RESUME_DATA";
export const USER_RESUME_DRAFT_DATA_CACHE = "USER_RESUME_DRAFT_DATA_CACHE";
export const USERCHOSEN_ISREPORTER = "USERCHOSEN_ISREPORTER";
export const USERCHOSEN_ISERROR = "USERCHOSEN_ISERROR";
export const VIEW_STATEMENT_DATA = "VIEW_STATEMENT_DATA";
export const REQUEST_CUSTOM_STATEMENT_SUCCESS = "REQUEST_CUSTOM_STATEMENT_SUCCESS";
export const USERCHOSEN_UPLOADED_MEDIA = "USERCHOSEN_UPLOADED_MEDIA";
export const MY_MESSAGE_DATA = "MY_MESSAGE_DATA";
export const ASSET_IDENTIFIERS = "ASSET_IDENTIFIERS";
export const RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY =
  "RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY";
export const SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION =
  "SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION";


// Adding case question related data
export const CASE_CREATION_MASTER_DATA = "CASE_CREATION_MASTER_DATA";
export const CASE_CREATION_ID = "CASE_CREATION_ID";
export const CASE_CREATION_ADDITINALINFO = "CASE_CREATION_ADDITINALINFO";
export const CASE_CREATION_DESCRIPTION = "CASE_CREATION_DESCRIPTION";
export const CASE_SELECTED_ANSWERS = "CASE_SELECTED_ANSWERS";
export const CASE_CREATION_QUESTIONS = "CASE_CREATION_QUESTIONS";
export const CLEAR_CASE_STATE = "CLEAR_CASE_STATE";
export const CASE_DESCRIPTION_DATA_INFO = "CASE_DESCRIPTION_DATA_INFO";
export const CASE_QUESTION_ANSWERS_DETAILS = "CASE_QUESTION_ANSWERS_DETAILS";
export const CASE_PROPERTY_TENANCY_GROUP = "CASE_PROPERTY_TENANCY_GROUP";
export const CASE_IDENTIFIER = "CASE_IDENTIFIER";
export const CASE_SUBTYPE = "CASE_SUBTYPE";
export const CASE_SUBTYPE_CATEGORY = "CASE_SUBTYPE_CATEGORY";
export const CASE_OWNING_TEAM = "CASE_OWNING_TEAM";
export const CASE_EDIT = "CASE_EDIT";
export const CASE_ELIGIBILITY_RESPONSE = "CASE_ELIGIBILITY_RESPONSE";
export const CASE_ADDITIONAL_CASE_FIELD = "CASE_ADDITIONAL_CASE_FIELD";
export const CASE_CATEGORY = "CASE_CATEGORY";
export const CASE_COUNTER = "CASE_COUNTER";
export const CASE_ASSET_TYPE = "CASE_ASSET_TYPE";
export const CASE_OWNER = "CASE_OWNER";
export const CASE_ASSET_IDENTIFIER = "CASE_ASSET_IDENTIFIER";
export const CASE_SELECTED_TENANCY = "CASE_SELECTED_TENANCY";
export const USERCHOSEN_ACCOUNT_Identifier = "USERCHOSEN_ACCOUNT_Identifier";
export const USERCHOSEN_REPORTER_ID = "USERCHOSEN_REPORTER_ID";
export const REPAIR_SEARCH_TERM = "REPAIR_SEARCH_TERM";
export const DEFAULT_SEARCH_REPAIR_STATUS = "DEFAULT_SEARCH_REPAIR_STATUS";
export const DEFAULT_SEARCH_AREA = "DEFAULT_SEARCH_AREA";
export const DEFAULT_SEARCH_SORT_BY_ORDER = "DEFAULT_SEARCH_SORT_BY_ORDER";

export const IS_CATALYST_CUSTOMER = "IS_CATALYST_CUSTOMER";
export const IS_CASE_CATALYST_TOGGLE ="IS_CASE_CATALYST_TOGGLE"
export const IS_CATALYST_REPAIR_DISABLED_AREA = "IS_CATALYST_REPAIR_DISABLED_AREA";
export const IS_WORKORDER_TOGGLE = "IS_WORKORDER_TOGGLE";
export const IS_EDIT_PROFILE_TOGGLE = "IS_EDIT_PROFILE_TOGGLE";
export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";
export const UPLOADED_MEDIA_ITEMS = "UPLOADED_MEDIA_ITEMS";
export const DELETE_MEDIA_ITEM = "DELETE_MEDIA_ITEM";
export const CLEAR_MEDIA_FILES = "CLEAR_MEDIA_FILES";
export const USERCHOSEN_DELETE_MEDIA = "USERCHOSEN_DELETE_MEDIA";
export const HAS_MEDIA_ATTACHED = "HAS_MEDIA_ATTACHED";
export const FROM_MEDIA_PAGE = "FROM_MEDIA_PAGE";
export const MEDIA_RESUME = "MEDIA_RESUME";
export const SELECTED_HISTORY_MEDIA = "SELECTED_HISTORY_MEDIA";
export const ENERGY_ADVICE_MAX_COUNT = "ENERGY_ADVICE_MAX_COUNT";
export const CASES_USERCHOSEN_PROPERTY_ID = "CASES_USERCHOSEN_PROPERTY_ID";
export const TAB_SELECTED_VALUE ="TAB_SELECTED_VALUE";
export const SHOW_MENU_TAB = "SHOW_MENU_TAB";
export const SHOW_FOOTER = "SHOW_FOOTER";
export const SINGLE_TENANCY = "SINGLE_TENANCY"
export const NAV_HEADER_TEXT = "NAV_HEADER_TEXT";
export const BLOCK_IDENTIFIER = 'BLOCK_IDENTIFIER';
export const USER_SELECTED_MESSAGE_DETAILS = "USER_SELECTED_MESSAGE_DETAILS";
export const USER_SELECTED_MESSAGE_SUBJECT = "USER_SELECTED_MESSAGE_SUBJECT";
export const USER_SELECTED_MESSAGE_DATE = "USER_SELECTED_MESSAGE_DATE";
export const USER_SELECTED_MESSAGE_SENDER = "USER_SELECTED_MESSAGE_SENDER";
export const USER_SELECTED_MESSAGE_NOTIFICATION_TYPE = "USER_SELECTED_MESSAGE_NOTIFICATION_TYPE";
export const USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER = "USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER";
export const USER_SELECTED_MESSAGE_STATUS = "USER_SELECTED_MESSAGE_STATUS";
export const FRA_DOCUMENTS= "FRA_DOCUMENTS";
export const BSR_DOCUMENTS= "BSR_DOCUMENTS";
export const RES_DOCUMENTS= "RES_DOCUMENTS";
export const ASSET_REGION = "ASSET_REGION";
export const NAVIGATED_FROM = "NAVIGATED_FROM";
export const REFERRAL_FOR = "REFERRAL_FOR"
export const REFERRAl_REASON = "REFERRAl_REASON"
export const REFERRAL_INCLUSION_TEAM = "REFERRAL_INCLUSION_TEAM"
export const REFERRAL_OTHER_DETAILS = "REFERRAL_OTHER_DETAILS"
export const CLEAR_REFERRAL_INFO = "CLEAR_REFERRAL_INFO"
export const COMING_FROM_REFERRAL = "COMING_FROM_REFERRAL"
export const REFERRAL_ADDITIONAL_DETAILS = "REFERRAL_ADDITIONAL_DETAILS"
export const REFERRAL_ACCOUNT_BALANCE = "REFERRAL_ACCOUNT_BALANCE"
export const COMING_FROM_CASE = 'COMING_FROM_CASE'
export const REASON_RESPONSE = 'REASON_RESPONSE'
export const BOROUGH_NAME = 'BOROUGH_NAME'

export const IS_CASE_JOURNEY = 'IS_CASE_JOURNEY'
export const IS_REPAIR_JOURNEY = 'IS_REPAIR_JOURNEY'
export const SELECTED_SLOT = 'SELECTED_SLOT'
export const IS_TRIAGE_REPAIR = 'IS_TRIAGE_REPAIR'
export const USER_SELECTED_TENANCY_GROUP = 'USER_SELECTED_TENANCY_GROUP'
export const IS_ON_HOLD_REPAIR = 'IS_ON_HOLD_REPAIR'
export const DEMOBILIZED_CONTRACTOR_SITES = "DEMOBILIZED_CONTRACTOR_SITES";
export const SAVE_ASSET_ID = "SAVE_ASSET_ID"

