import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { 
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import * as api from "../../api";

import Loader from "react-loader-spinner";
import {  
  updateDashboardForm,
  fetchRepairReasons,
  fetchRepairReasonsSelect,
} from "../../ducks/redux/actions/dashboardReducer.actions";
import RepairReason2 from "./index2";
import "./index.scss";

import { RouteComponentProps, useLocation } from "react-router-dom";
import Button from "../../storybook/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { CurrentRepair } from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import { Address, CustomerName } from "../../models/Customer";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import BackModal from "../Modal/BackModal";
import {
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  resumeDraftRepair,  
  userCreatedNewRepair,
  userChosenResumeRepair,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
} from "../../ducks/redux/actions/userChoices";

interface RepairReasonProps extends RouteComponentProps<any> { }

const RepairReason: React.FC<RepairReasonProps> = (props) => {
  const dispatch = useDispatch();

  //To track radio button selection for RepairProperty--Start here---

  //-----------Ends here-----------//
  enum RepairReportingMethods {
    "Telephone" = 1,
    "Email" = 2,
    "Social media" = 3,
    "Customer Self-Serve" = 4,
  }
  const [selectedreportingMethod, setSelectedreportingMethod] = useState(
    "Customer Self-Serve"
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [repairLocation, setRepairLocation] = useState("");

  const [isDisabled, setButtonDisabled] = useState(true);
  const [chosenHL, setChosenHL] = useState("");
  const [locatioAlert, setLocatioAlert] = useState<string[]>([]);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  let alertMstArray: any[] = [];
  let SaveandExitFlag = false;
  const addresses = useSelector(
    (state: any) => state.addresses?.customerassociatedassets
  );

  const fetchedRepairReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.fetchedRepairReasons
  );

  const userTypedRepairReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );

  const userChosenPropertyType = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );

  const userChosenRepairLocationIdValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );

  const userChosenHighLevelDescriptionValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenHighLevelDescription
  );

  const [fetchedRepairReasonsData, setData] = useState(fetchedRepairReasons);

  const selectedRepairReasonObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject
  );

  const userChosenUPRN = useSelector(
    (state: any) => state.userChoicesReducer.userChosenUPRN
  );

  const tenanciesData = useSelector(
    (state: any) => state.addresses.customertenancies
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );

  const assetId = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.customerID?.assetId
  );

  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const userChosenResumeRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenResumeRepair
  );

  const loadDuplicateRepairPageValue = useSelector(
    (state: any) => state.userChoicesReducer.loadDuplicateRepairPage
  );

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  
  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );

  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );
  const repairPriority = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );
  let emergencyState = false;
  if (selectedproperty === "Property" && priority === "Emergency") {
    if (userChosenEmergencyQuestionVal === "Yes, I can provide access") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  } else {
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  }

  const workOrderRepair = new CurrentRepair();
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const _premisesTypeId: any =
    PremisesTypeId[selectedproperty as keyof typeof PremisesTypeId];
  workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
  workOrderRepair.description = selectedRepairReasonObject[0]?.description;

  workOrderRepair.premisesId = assetId;
  if (selectedproperty === "Divided Block") {
    workOrderRepair.premisesTypeId = "5";
  } else {
    workOrderRepair.premisesTypeId = _premisesTypeId;
  }
  workOrderRepair.customerIdentifier = customerID;
  workOrderRepair.isEmergency = emergencyState;
  workOrderRepair.premisesUniqueReference = userChosenUPRN;
  const _address = new Address();
  _address.addressLine1 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
  _address.addressLine2 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
  _address.addressLine3 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
  workOrderRepair.premisesAddress = _address;
  workOrderRepair.sorCode = selectedRepairReasonObject[0]?.sors[0]?.sorCode;
  workOrderRepair.customerName = new CustomerName({
    title: customerDetails?.person?.name?.title,
    givenName: customerDetails?.person?.name?.givenName,
    surname: customerDetails?.person?.name?.surname,
  });
  workOrderRepair.reportingMethodId =
    RepairReportingMethods[
    selectedreportingMethod as keyof typeof RepairReportingMethods
    ];
  workOrderRepair.reportingMethod = selectedreportingMethod;

  workOrderRepair.notifyAll = false;
  workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
  workOrderRepair.eligibilityOutcomes = [];
  workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
  workOrderRepair.repairLocationId = userChosenRepairLocationIdValue;
  workOrderRepair.repairCause = "General Wear and Tear";
  workOrderRepair.priority = repairPriority
  workOrderRepair.repairCauseId =
    RepairCauseId[workOrderRepair.repairCause as keyof typeof RepairCauseId];
  const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
  const woRequestParameters =
    _worksOrderRequest.createWorkOrderParameter(workOrderRepair);

  const CreateWorkOrder = async () => {
    try {      
      setIsLoading(true);
      if (!chosenHL) {
        woRequestParameters.repairReason = "";
        woRequestParameters.description = "";
      }
      if(userChosenSOR==="N/A"){
        woRequestParameters.sorCode = "NA";
        workOrderRepair.sorCode ="NA";
        }
        else {
          woRequestParameters.sorCode = userChosenSOR;
          workOrderRepair.sorCode =userChosenSOR;
        }
      woRequestParameters.isCatalyst = isCatalyst;
      woRequestParameters.isInDefect = eligibilityResponseObject?.statuses.isInDefects
      const apiResponse = await api.createWorkOrderRepair({
        ...woRequestParameters,
      });

      if (apiResponse?.location.length > 0) {
        const workorderLocation = apiResponse?.location.split("/");
        workOrderRepair.id = workorderLocation[4];
        workOrderRepair.worksOrderIdentifier = workorderLocation[4];
        workOrderRepair.isCatalyst = isCatalyst;
        dispatch(workOrderRepairObject(workOrderRepair));
        dispatch(userCreatedNewRepair(workOrderRepair));
      }
      updateWODirectToNextPage();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonHandler = () => {
    if (!resumeDraftRepairVal) {
      dispatch(userChosenRepairLocationId(0));
      dispatch(userChosenHighLevelDescription(""));
    }
    props.history.push("/repairasset");
  };

  const UpdateWorkOrder = async () => {
    try {
      setIsLoading(true);
      if (workOrderObject.id.trim().length > 0 && EditState != true) {

        const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
        const deleteWorkOrderResponse: any = await api.deleteWorkOrder(workOrderObject.id, getWorkOrderResult.eTag.toString());   
          Promise.all([getWorkOrderResult,deleteWorkOrderResponse]).then(async (results) => {
            const workOrderRepair = new CurrentRepair();
            enum RepairCauseId {
              "General Wear and Tear" = 1,
              "Vandalism or Damage" = 4,
            }
            const _premisesTypeId: any =
              PremisesTypeId[selectedproperty as keyof typeof PremisesTypeId];
            workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
            workOrderRepair.description = selectedRepairReasonObject[0]?.description;

            workOrderRepair.premisesId = assetId;
            if (selectedproperty === "Divided Block") {
              workOrderRepair.premisesTypeId = "5";
            } else {
              workOrderRepair.premisesTypeId = _premisesTypeId;
            }
            workOrderRepair.customerIdentifier = customerID;
            workOrderRepair.isEmergency = emergencyState;
            workOrderRepair.premisesUniqueReference = userChosenUPRN;
            const _address = new Address();
            _address.addressLine1 =
              customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
            _address.addressLine2 =
              customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
            _address.addressLine3 =
              customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
            workOrderRepair.premisesAddress = _address;
            workOrderRepair.sorCode = selectedRepairReasonObject[0]?.sors[0]?.sorCode;
            workOrderRepair.customerName = new CustomerName({
              title: customerDetails?.person?.name?.title,
              givenName: customerDetails?.person?.name?.givenName,
              surname: customerDetails?.person?.name?.surname,
            });
            workOrderRepair.reportingMethodId =
              RepairReportingMethods[
              selectedreportingMethod as keyof typeof RepairReportingMethods
              ];
            workOrderRepair.reportingMethod = selectedreportingMethod;
            workOrderRepair.priority = repairPriority
            workOrderRepair.notifyAll = false;
            workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
            workOrderRepair.eligibilityOutcomes = [];
            workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
            workOrderRepair.repairLocationId = userChosenRepairLocationIdValue;
            workOrderRepair.repairCause = "General Wear and Tear";

            workOrderRepair.repairCauseId =
              RepairCauseId[workOrderRepair.repairCause as keyof typeof RepairCauseId];
            const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
            const woRequestParameters =
              _worksOrderRequest.createWorkOrderParameter(workOrderRepair);
            woRequestParameters.repairReason =
              selectedRepairReasonObject[0]?.description;
            woRequestParameters.description =
              selectedRepairReasonObject[0]?.description;

            woRequestParameters.isCatalyst = isCatalyst;
            woRequestParameters.isInDefect = eligibilityResponseObject?.statuses.isInDefects

          
            const apiResponse = await api.createWorkOrderRepair({
              ...woRequestParameters,
            });

            if (apiResponse?.location.length > 0) {
              const workorderLocation = apiResponse?.location.split("/");
              workOrderRepair.id = workorderLocation[4];
              workOrderRepair.worksOrderIdentifier = workorderLocation[4];
              dispatch(workOrderRepairObject(workOrderRepair));
            }
            workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
            workOrderRepair.description = selectedRepairReasonObject[0]?.description;
            dispatch(workOrderRepairObject(workOrderRepair));
            dispatch(userCreatedNewRepair(workOrderRepair));
            updateWODirectToNextPage();
          });
        }
        else {
          const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
          const woRequestParameters =
            _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
          woRequestParameters.repairReason =
            selectedRepairReasonObject[0]?.description;
          woRequestParameters.description =
            selectedRepairReasonObject[0]?.description;

          await api.updateWorkOrderRepair(
            woRequestParameters,
            workOrderObject.id,
            "eTag"
          );

          workOrderObject.repairReason = selectedRepairReasonObject[0]?.description;
          workOrderObject.description = selectedRepairReasonObject[0]?.description;
          dispatch(workOrderRepairObject(workOrderObject));
          dispatch(userCreatedNewRepair(workOrderRepair));
          updateWODirectToNextPage();
        }
     
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const updateWODirectToNextPage = () => {
    if(SaveandExitFlag){
      props.history.push("/customer360View");
    }
    else{
      props.history.push("/supplementaryquestion");
    }
  }

  useEffect(() => {
    (async function () {
      try {
        const premiseType =
          userChosenPropertyType === "Block" ||
            userChosenPropertyType === "Estate" ||
            userChosenPropertyType === "Divided Block" ||
            userChosenPropertyType === "Divided block"
            ? "Communal"
            : userChosenPropertyType;
        const responsedata = await api.searchRepairReasons(
          userTypedRepairReasons,
          premiseType,
          isCatalyst
        );
        // console.log(`responsedata constains: ${JSON.stringify(responsedata)}`);

        dispatch(fetchRepairReasonsSelect(responsedata));

        const extractedReasons: string[] = [];

        responsedata.map((eachData, key) => {
          const { description } = eachData;
          extractedReasons.push(description);
        });
        setData(extractedReasons);
        dispatch(fetchRepairReasons(extractedReasons));

        //dispatch(saveCustomerTenacies(activeTenanciesSummaries));
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/genericerror");
      }
    })();
  }, [addresses, userTypedRepairReasons]);

  useEffect(() => {
    if (userChosenRepairLocationIdValue) {
      setRepairLocation(userChosenRepairLocationIdValue);
      //setdisabled(true);
    }
    alertMstArray = [];
    setLocatioAlert([]);
    dispatch(userCreatedNewRepair(null));
    SaveandExitFlag = false;
  }, []);

  //redux data to check radio button selection
  const updateSelection = (e: any) => {
    dispatch(
      updateDashboardForm({
        selected: e.target.value,
      })
    );
  };
  //ends here

  let fieldsArray: any[] = [];
  addresses?.forEach((address: any) => {
    const addressValue =
      address?.addresses?.postalAddresses[0]?.formattedAddress;

    fieldsArray.push(
      <FormControlLabel
        value={addressValue}
        control={<Radio onChange={updateSelection} />}
        label={addressValue}
      />
    );
  });

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    SaveandExitFlag =true;
    workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();
  };

  const getMandatoryData = (data: any) => {
    setChosenHL(data);
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { repairLocationId, repairReason } = userResumeDraftDataCache;
      if (
        userChosenRepairLocationIdValue !== repairLocationId ||
        chosenHL !== repairReason
      ) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenSupplementaryQuestions(""));
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(userChosenDuplicateQuestion(""));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
      if(repairLocationId == "" || repairReason == "")
      {
        if (workOrderObject.id.trim().length > 0) {
          workOrderObject.repairLocationId = userChosenRepairLocationIdValue;
          workOrderObject.repairReason = selectedRepairReasonObject[0]?.description;
          workOrderObject.description = selectedRepairReasonObject[0]?.description;
        }
        dispatch(userChosenResumeRepair(false));
        dispatch(workOrderRepairObject(workOrderObject));
        dispatch(userCreatedNewRepair(workOrderObject));
        props.history.push("/supplementaryquestion");
        return;
      }
    }

    if (
      !chosenHL &&
      (userChosenRepairLocationIdValue == 0 ||
        userChosenRepairLocationIdValue === "No option selected global-txt")
    ) {
      alertMstArray.push(t("SSR051"));
      alertMstArray.push(t("SSR052"));
      setLocatioAlert(alertMstArray);
      return;
    } else if (
      userChosenRepairLocationIdValue == 0 ||
      userChosenRepairLocationIdValue === "No option selected global-txt"
    ) {
      alertMstArray.push(t("SSR051"));
      setLocatioAlert(alertMstArray);
      return;
    } else if (!chosenHL || (!chosenHL && userChosenRepairLocationIdValue)) {
      alertMstArray.push(t("SSR052"));
      setLocatioAlert(alertMstArray);
      return;
    }

    alertMstArray = [];
    setLocatioAlert([]);

    //logic to create/update workorder
    if (!userChosenResumeRepairValue && !loadDuplicateRepairPageValue) {
      workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();
    }
    
  };

  return (
    <>
      <div id="repreason-main-div" className="padding-repair">
        <RepairReason2
          suggestions={fetchedRepairReasons}
          setButtonDisabled={setButtonDisabled}
          locatioAlert={locatioAlert}
          getMandatoryData={getMandatoryData}
          {...props}
        />
      </div>
      {isLoading && (
            <div className="pl-2">
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={50}
                width={50}
              />
            </div>
          )
      }
      <div className="col-md-12 marginTop-auto" id="repreason-main-btn">
        <div className="row m-0 footer-actionbtn">
        <div className="col btn-top padding-repair mb-0">
          <BackModal id="reploc-backmodal" back={backButtonHandler} />
          <ExitModalAndSave
          id="repreason-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
          </div>
          <div className="col text-end padding-repair">
          <Button
          id="btn_repairreason"
          type="button"
          data-testid="btncontinue"
          name="btncontinue"
          disabled={isDisabled}
          onClick={callPageHandler}
          placeholder="btncontinue"
          value="Continue"
          className="primary-btn"
        >
          {t("continuen_button")}
        </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairReason;
