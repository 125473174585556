import React, { useEffect, useState } from "react";
import "./index.scss";
import peabody from "../../assets/img/peabody-logo-teal.png";
import closemenu from "../../assets/img/close.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Hotjar from '@hotjar/browser'
import { loginStatus, loginTime, selectedTabValue, selectedTenancyGroup, userChosenAdditionalDescription, userChosenContactDetails, userChosenContactName, userChosenContactPhone, userChosenCovidQuestion, userChosenDuplicateQuestion, userChosenEligibilityErrorCode, userChosenHighLevelDescription, userChosenPropertyAddress, userChosenPropertyId, userChosenPropertyType, userChosenRelationDetails, userChosenRepairLocation, userChosenSOR, userChosenSTATUS, userChosenSupplementaryQuestions, userChosenUPRN, userChosenVandalismAdditionalInformation, userChosenVandalismQuestion, userChosenVulnerabilityAdditionalInformation, userChosenVulnerabilityQuestion, userProfileData, demobilizedContractorSites, isMyPbRepairsMaintainence, repairPriorityValue} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { getCustomerId } from "../../ducks/redux/actions/getAddress";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import HomeModal from "../../compositions/Modal/HomeModal";
import {
    CASE_SELECTED_ANSWERS,
    CLEAR_CASE_STATE,
} from "../../ducks/redux/actionTypes";


type HeaderProps = {
    name: string;
};
const b2cLoginUrl: string = process.env.REACT_APP_CSS_B2C_LOGIN_URL || "";
const siteId = process.env.REACT_APP_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;


const Header: React.FC<HeaderProps> = ({ name }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [isVisiableSignIn, setIsVisiableSignIn] = useState("block");
    const [isVisiableSignOut, setIsVisiableSignOut] = useState("none");


    const [showMenu, setShowMenu] = useState(true);

    const [checkboxMenu, setCheckboxMenu] = useState(false)

    const loggedInStatus = useSelector(
        (state: any) => state?.userChoicesReducer?.loginStatus
    );

    const demobilizedContractorSitesCheck = useSelector(
        (state: any) => state?.userChoicesReducer?.demobilizedContractorSites
    );

    const isMyPBMaintenance = useSelector(
        (state: any) => state?.userChoicesReducer?.isMyPbRepairsMaintainence
    );

    const selectedTab = useSelector(
        (state: any) => state?.userChoicesReducer?.selectedTabValue
    );

    const showTabs = useSelector(
        (state: any) => state?.userChoicesReducer?.showMenuTabs
    );

    const isCatalyst = useSelector(
        (state: any) => state.userChoicesReducer?.isCatalystCustomer
    );
    const appCaseCardconfigValue = useSelector(
        (state: any) => state?.addresses?.caseCardtoggleValue
    )

    const appCaseCardCatalystconfigValue = useSelector(
        (state: any) => state?.userChoicesReducer?.isCaseCatalystToggle
    )

    const hotjarScriptCall = async () => {
        const appConfigHotjarToggleValue = await api.GetAppConfigToggleValue(
            "IsHotjarEnabledSS"
        );
        if (appConfigHotjarToggleValue.value === "true") {
            if (siteId != undefined && hotjarVersion != undefined) {
                const hotJarSiteIdParam = parseInt(siteId);
                const hotjarVersionParam = parseInt(hotjarVersion);
                Hotjar.init(hotJarSiteIdParam, hotjarVersionParam);
            }
        }
    }

    const getDemobilizedContractorSites = async () => {

        if (isUndefinedNullOrEmpty(demobilizedContractorSitesCheck) || demobilizedContractorSitesCheck.length == 0) {
            try {
                const response = await api.GetAppConfigToggleValue(
                    "demobilizedContractorSites"
                );
                dispatch(demobilizedContractorSites(response.value.split(",")))
            }
            catch (error) {
                console.error("Failed to fetch demobilized contractor sites:", error);
            }
        }
    }

    const getIsMyPbRepairsMaintenance = async () => {
        if (isUndefinedNullOrEmpty(isMyPBMaintenance) || isMyPBMaintenance.length == 0)
            try {
                const response = await api.GetAppConfigToggleValue("isMyPbRepairsMaintainence");

                const isMyPBMaintenanceValue = response?.value?.toLowerCase() === 'true';
                dispatch(isMyPbRepairsMaintainence(isMyPBMaintenanceValue))
            }
            catch (error) {
                console.error("Failed to fetch isMyPbRepairsMaintainence:", error);
            }
    }


    useEffect(() => {
        if (isUndefinedNullOrEmpty(loggedInStatus)) {
            setIsVisiableSignIn("block");
            setIsVisiableSignOut("none");
        } else {

            setIsVisiableSignIn("none");
            setIsVisiableSignOut("block");
            hotjarScriptCall();
            getDemobilizedContractorSites();
            getIsMyPbRepairsMaintenance();
        }
    }, [loggedInStatus, showTabs]);



    const RedirectToIDAMLoginPage = () => {
        window.location.href = b2cLoginUrl;
    };

    const RedirectToCustomer360View = () => {
        setMenu();
        history.push("/customer360View");
        dispatch(selectedTabValue("home"))
    }

    const RedirectToViewBalance = () => {
        setMenu();
        history.push("/viewaccount");
        dispatch(selectedTabValue("viewaccount"))

    }

    const RedirectToViewRepairsHistory = () => {
        setMenu();
        history.push("/historicRepairs");
        dispatch(selectedTabValue("historicRepairs"))


    }

    const RedirectToViewMessageHistory = () => {
        setMenu();
        history.push("/mymessageshistory");
        dispatch(selectedTabValue("mymessageshistory"))

    }

    const RedirectToViewYourDetails = () => {
        setMenu();
        history.push("/manageprofile");
        dispatch(selectedTabValue("manageprofile"))
    }

    const RedirectToLogOut = () => {
        setMenu();
        RedirectToCSSLoginPage();
        dispatch(selectedTabValue("logout"))
    }

    const RedirectToRequest = () => {
        setMenu();
        history.push("/casesearch");
        dispatch(selectedTabValue("requests"));
    }

    const setMenu = () => {
        setCheckboxMenu(!checkboxMenu)
        setShowMenu(!showMenu)
    }

    const clearCaseStoreData = () => {
        dispatch({ type: CLEAR_CASE_STATE });
        dispatch({ type: CASE_SELECTED_ANSWERS });
    };
    const HomeModalHandler = () => {
        clearCaseStoreData();
        history.push("/customer360View");
    };
    const peabodyWebsite = () => {
        window.open("https://www.peabody.org.uk/",
            "", "width=auto, height=auto");
    }
    const RedirectToCSSLoginPage = () => {
        dispatch(loginTime(""));
        dispatch(loginStatus(false));
        dispatch(loginStatus(""));
        dispatch(getCustomerId(""));
        clearCaseStoreData();
        const apiResponseDeleteSessionID = api.clearSession();
        localStorage.clear();
        dispatch(
            userProfileData("")
        );
        dispatch(userChosenPropertyId(""));
        dispatch(userChosenPropertyType(""));
        dispatch(userChosenPropertyAddress(""));
        dispatch(userChosenRepairLocation(""));
        dispatch(userChosenHighLevelDescription(""));
        dispatch(userChosenSupplementaryQuestions(""));
        dispatch(userChosenDuplicateQuestion(""));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion(""));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenUPRN(""));
        dispatch(userChosenSOR(""));
        dispatch(userChosenSTATUS(""));
        dispatch(userChosenEligibilityErrorCode(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
        dispatch(userChosenRelationDetails(""));
        dispatch(selectedTenancyGroup(''))
        dispatch(demobilizedContractorSites([]));
        dispatch(isMyPbRepairsMaintainence(""))
        dispatch(repairPriorityValue(""))
        history.push("/startpage");
    };
    return (
        <>
            {showTabs && (
                <header className="container-fluid p-0">
                    <nav className="navbar">
                        <div className="header flex justify-between items-center">
                            <a data-testid="peabody_logo" className="" onClick={peabodyWebsite}>
                                <img src={peabody} className="logo" alt="Peabody Logo" />
                            </a>
                        </div>

                        <ul className="nav-links">
                            <input onClick={setMenu} checked={checkboxMenu} type="checkbox" id="checkbox_toggle" />
                            {showMenu ? (
                                <label htmlFor="checkbox_toggle" className="hamburger">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none" aria-label="Close Menu">
                                        <path d="M4.5 27.4922H31.5V24.4922H4.5V27.4922ZM4.5 19.9922H31.5V16.9922H4.5V19.9922ZM4.5 9.49219V12.4922H31.5V9.49219H4.5Z" fill="#1CABB6" />
                                    </svg>
                                </label>
                            ) : (
                                <label htmlFor="checkbox_toggle" className="closemenu-icon">
                                    <a>
                                        <img src={closemenu} className="" alt="Close Menu" />
                                    </a>
                                </label>
                            )}
                            <div className="menu">
                                <li onKeyDown={RedirectToCustomer360View} onClick={RedirectToCustomer360View} className={selectedTab === 'home' ? 'active' : ''}><a >{t("Header_Nav_Txt_Home")}</a></li>
                                <li onKeyDown={RedirectToViewBalance} onClick={RedirectToViewBalance} className={selectedTab === 'viewaccount' ? 'active' : ''}><a >{t("Header_Nav_Txt_Balance")}</a></li>
                                <li onKeyDown={RedirectToViewRepairsHistory} onClick={RedirectToViewRepairsHistory} className={selectedTab === 'historicRepairs' ? 'active' : ''}><a  >{t("Header_Nav_Txt_Repairs")}</a></li>
                                <li onKeyDown={RedirectToViewMessageHistory} onClick={RedirectToViewMessageHistory} className={selectedTab === 'mymessageshistory' ? 'active' : ''}><a >{t("Header_Nav_Txt_Messages")}</a></li>
                                <li onKeyDown={RedirectToViewYourDetails} onClick={RedirectToViewYourDetails} className={selectedTab === 'manageprofile' ? 'active' : ''}><a >{t("Header_Nav_Txt_Details")}</a></li>
                                {isCatalyst && appCaseCardCatalystconfigValue == "true" && appCaseCardconfigValue == "true" ?
                                    <li onClick={RedirectToRequest} className={selectedTab === 'requests' ? 'active' : ''}><a >{t("Header_Nav_Txt_Request")}</a>
                                    </li>
                                    :
                                    !isCatalyst && appCaseCardconfigValue == "true" ?
                                        <li onClick={RedirectToRequest} className={selectedTab === 'requests' ? 'active' : ''}><a >{t("Header_Nav_Txt_Request")}</a>
                                        </li>
                                        :
                                        <></>}
                                <li onKeyDown={RedirectToLogOut} onClick={RedirectToLogOut} className={selectedTab === 'logout' ? 'active' : ''}><a >{t("Header_Nav_Txt_Logout")}</a></li>
                            </div>

                        </ul>
                    </nav>

                </header>
            )}
        </>
    );
};
export default Header;